<template>
  <div v-if="dataReady" class="onboarding-review-admin onboarding-review-admin-page">
    <h2 class="font-medium text-xl mt-6 md:mt-0 md:text-2xl lg:text-3xl mb-6 md:mb-8 lg:mb-10" style="line-height: 25px">Onboarding application &nbsp;-&nbsp; {{ onboardDetail.onboardingId }}</h2>

    <vs-row id="profile-page" class="mt-5" v-if="isReady">
      <!-- Business Information -->
      <vx-card class="accordin-card">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="edit" alt="edit">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business information</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <vs-row>
                <!-- General Information -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">General information</h4>
                  </div>
                  <hr class="line-hr mb-8 md:mb-10 w-full ml-1">

                  <GeneralInformation :generalInfo="onboardDetail.businessInfo"/>
                </div>

                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Business profile</h4>
                  </div>
                  <hr class="line-hr w-full ml-1">
                  <div class="onboarding-form">
                    <div class="flex mt-6 flex-wrap" v-if="onboardDetail && onboardDetail.creditLimit">
                      <div class="input-grp">
                        <label class="vs-input--label pl-0 review-label w-full">Pre-assigned credit limit</label>
                        <p class="textBlack">{{ `$ ${addZeroes(onboardDetail.creditLimit)}` }}</p>
                      </div>
                    </div>
                  </div>

                  <vs-row>
                    <div class="w-full">
                      <BusinessProfile :businessProfile="onboardDetail.businessProfile" :onboardingCustomFields="onboardDetail.onboardingCustomFields" :message="onboardDetail.onboardingRequest && onboardDetail.onboardingRequest.onboardingProfileMessage ? onboardDetail.onboardingRequest.onboardingProfileMessage : null"/>
                    </div>
                  </vs-row>
                </div>

                <!-- Contact details -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Contact details</h4>
                  </div>
                  <hr class="line-hr mb-8 w-full ml-1" />
                  <ContactDetails :contactDetails="onboardDetail.contactDetails" :onboardingRequestId="onboardingId" :applicationStatus="onboardDetail.status"/>
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Business ownership -->
      <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessOwnership" :open="isReady">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business ownership</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BusinessOwnership ref="businessOwnershipChild" :onboardingRequestId="onboardingId" :entityList="onboardDetail.businessOwnership" :businessOwnershipCustomFields="businessOwnershipCustomFields"/>
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Bank Account -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="bankCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Bank accounts</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BankAccounts :bankAccount="onboardDetail.bankAccount" :entityList="onboardDetail.businessOwnership"/>
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Product Selection -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="product" alt="product">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Product selection</h3>
                </div>
              </div>

              <div>
                <ProductSelection :product="onboardDetail.productSelection" :banks="onboardDetail.bankAccount" />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

       <!-- Users Component -->
       <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="product" alt="product">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Users</h3>
                </div>
              </div>

              <div>
                <user-component :users="onboardDetail.users"/>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Onboarding Agreement -->
      <vx-card class="accordin-card mt-4" v-if="legalsReady">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="edit" alt="home" />
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">{{ currentLegal.documentName }}</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <div class="w-full">
                <OnboardingAgreement :legalsInfo="currentLegal" :terms="onboardDetail.terms"/>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>
    </vs-row>

    <!-- Supporting documents -->
    <vx-card class="accordin-card mt-4">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item ref="document" :open="true">
            <div slot="header">
              <div  class="flex items-center">
                <div class="icon-wrapper">
                  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.95856 1.88239C1.57232 1.26863 2.40475 0.923828 3.27273 0.923828H12C12.2893 0.923828 12.5668 1.03876 12.7714 1.24335L19.3168 7.7888C19.5214 7.99339 19.6364 8.27086 19.6364 8.56019V21.6511C19.6364 22.5191 19.2916 23.3515 18.6778 23.9653C18.0641 24.579 17.2316 24.9238 16.3636 24.9238H3.27273C2.40475 24.9238 1.57231 24.579 0.95856 23.9653C0.344804 23.3515 0 22.5191 0 21.6511V4.19656C0 3.32857 0.344804 2.49614 0.95856 1.88239ZM3.27273 3.10565C2.9834 3.10565 2.70592 3.22058 2.50134 3.42517C2.29675 3.62975 2.18182 3.90723 2.18182 4.19656V21.6511C2.18182 21.9404 2.29675 22.2179 2.50134 22.4225C2.70592 22.6271 2.9834 22.742 3.27273 22.742H16.3636C16.653 22.742 16.9304 22.6271 17.135 22.4225C17.3396 22.2179 17.4545 21.9404 17.4545 21.6511V9.01206L11.5481 3.10565H3.27273Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.923828C12.6025 0.923828 13.0909 1.41224 13.0909 2.01474V7.46928H18.5455C19.1479 7.46928 19.6364 7.9577 19.6364 8.56019C19.6364 9.16268 19.1479 9.6511 18.5455 9.6511H12C11.3975 9.6511 10.9091 9.16268 10.9091 8.56019V2.01474C10.9091 1.41224 11.3975 0.923828 12 0.923828Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 14.0147C4.36364 13.4122 4.85205 12.9238 5.45455 12.9238H14.1818C14.7843 12.9238 15.2727 13.4122 15.2727 14.0147C15.2727 14.6172 14.7843 15.1056 14.1818 15.1056H5.45455C4.85205 15.1056 4.36364 14.6172 4.36364 14.0147Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 18.3784C4.36364 17.7759 4.85205 17.2875 5.45455 17.2875H14.1818C14.7843 17.2875 15.2727 17.7759 15.2727 18.3784C15.2727 18.9809 14.7843 19.4693 14.1818 19.4693H5.45455C4.85205 19.4693 4.36364 18.9809 4.36364 18.3784Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 9.6511C4.36364 9.04861 4.85205 8.56019 5.45455 8.56019H7.63636C8.23886 8.56019 8.72727 9.04861 8.72727 9.6511C8.72727 10.2536 8.23886 10.742 7.63636 10.742H5.45455C4.85205 10.742 4.36364 10.2536 4.36364 9.6511Z" fill="#00CFFF"/>
                  </svg>
                </div>
                <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">
                  Supporting documents
                </h3>
              </div>
            </div>

            <div>
              <SupportingDocument
                :document="onboardDetail.supportingDocuments"
                :onboardingRequestId="onboardDetail.onboardingRequestId"
                :showEditButton="showEditButton"
                @handleCardHeight="handleCardHeight('document')"
                :applicationStatus="onboardDetailStatusComputed"
              ></SupportingDocument>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <!-- Till details -->
    <vx-card class="accordin-card mt-4">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item ref="document" :open="true">
            <div slot="header">
              <div  class="flex items-center">
                <div class="icon-wrapper">
                  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.95856 1.88239C1.57232 1.26863 2.40475 0.923828 3.27273 0.923828H12C12.2893 0.923828 12.5668 1.03876 12.7714 1.24335L19.3168 7.7888C19.5214 7.99339 19.6364 8.27086 19.6364 8.56019V21.6511C19.6364 22.5191 19.2916 23.3515 18.6778 23.9653C18.0641 24.579 17.2316 24.9238 16.3636 24.9238H3.27273C2.40475 24.9238 1.57231 24.579 0.95856 23.9653C0.344804 23.3515 0 22.5191 0 21.6511V4.19656C0 3.32857 0.344804 2.49614 0.95856 1.88239ZM3.27273 3.10565C2.9834 3.10565 2.70592 3.22058 2.50134 3.42517C2.29675 3.62975 2.18182 3.90723 2.18182 4.19656V21.6511C2.18182 21.9404 2.29675 22.2179 2.50134 22.4225C2.70592 22.6271 2.9834 22.742 3.27273 22.742H16.3636C16.653 22.742 16.9304 22.6271 17.135 22.4225C17.3396 22.2179 17.4545 21.9404 17.4545 21.6511V9.01206L11.5481 3.10565H3.27273Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.923828C12.6025 0.923828 13.0909 1.41224 13.0909 2.01474V7.46928H18.5455C19.1479 7.46928 19.6364 7.9577 19.6364 8.56019C19.6364 9.16268 19.1479 9.6511 18.5455 9.6511H12C11.3975 9.6511 10.9091 9.16268 10.9091 8.56019V2.01474C10.9091 1.41224 11.3975 0.923828 12 0.923828Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 14.0147C4.36364 13.4122 4.85205 12.9238 5.45455 12.9238H14.1818C14.7843 12.9238 15.2727 13.4122 15.2727 14.0147C15.2727 14.6172 14.7843 15.1056 14.1818 15.1056H5.45455C4.85205 15.1056 4.36364 14.6172 4.36364 14.0147Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 18.3784C4.36364 17.7759 4.85205 17.2875 5.45455 17.2875H14.1818C14.7843 17.2875 15.2727 17.7759 15.2727 18.3784C15.2727 18.9809 14.7843 19.4693 14.1818 19.4693H5.45455C4.85205 19.4693 4.36364 18.9809 4.36364 18.3784Z" fill="#00CFFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 9.6511C4.36364 9.04861 4.85205 8.56019 5.45455 8.56019H7.63636C8.23886 8.56019 8.72727 9.04861 8.72727 9.6511C8.72727 10.2536 8.23886 10.742 7.63636 10.742H5.45455C4.85205 10.742 4.36364 10.2536 4.36364 9.6511Z" fill="#00CFFF"/>
                  </svg>
                </div>
                <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">
                  Till details
                </h3>
              </div>
            </div>

            <div v-if="onboardDetail.tillDetail.sendDate">
              <div class="flex mb-2 items-center ml-1">
                <div class="flex justify-center items-center" style="max-width:20px;"><img class="icon-control" :src="editlight" alt="edit"></div>
                <h4 class="text-xl mb-0 font-normal ml-5">General information</h4>
              </div>
              <hr class="line-hr mb-8 md:mb-10 w-full ml-1">
              <div class="onboarding-form">
                <div class="flex mt-6 flex-wrap">
                  <div class="input-grp">
                    <label class="vs-input--label w-full review-label">Date / sent</label>
                    <p class="textBlack mb-0">{{ onboardDetail.tillDetail.sendDate }}</p>
                  </div>

                  <div class="input-grp">
                    <label class="vs-input--label w-full review-label">Status</label>
                    <p class="textBlack mb-0">{{ onboardDetail.tillDetail.status }}</p>
                  </div>
                </div>
                <div class="flex mt-6 flex-wrap">
                  <div class="input-grp">
                    <label class="vs-input--label w-full review-label">CSV</label>
                    <S3FileGenerator v-if="onboardDetail.tillDetail.csvFile" :document="onboardDetail.tillDetail.csvFile" />
                    <p v-else class="textBlack mb-0">CSV file not available</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              No details available for this application
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <!-- Audit Log -->
    <vx-card class="accordin-card mt-4">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item ref="auditLogCollaspe" :open="true">
            <div slot="header">
              <div  class="flex items-center">
                <div class="icon-wrapper">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 6.92383H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 12.9238H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 18.9238H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 6.92383H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 12.9238H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 18.9238H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>

                <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">
                  Audit Log
                </h3>
              </div>
            </div>

            <vs-row>
              <div>
                <AuditLog :logs="onboardDetail.auditLogs" @handleCardHeight="handleCardHeight('auditLog')"/>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <div class="mt-10" v-if="dataError">
      <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full"/>
        <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">404 - Data Not Found!</h1>
        <vs-button size="large" @click="goToDashboard">Back to Home</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AuditLog from "./auditLog/AuditLog.vue";
import BankAccounts from "./bankAccount/BankAccounts.vue";
import BusinessOwnership from "./businessOwnership/BusinessOwnership";
import BusinessProfile from "./businessInformation/BusinessProfile.vue";
import ContactDetails from "./businessInformation/ContactDetails.vue";
import GeneralInformation from "./businessInformation/GeneralInformation.vue";
import OnboardingAgreement from "./agreement/OnboardingAgreement.vue";
import ProductSelection from "./product/ProductSelection.vue";
import S3FileGenerator from "../../components/S3FileGenerator";
import SupportingDocument from "./supportingDocument/SupportingDocument.vue";
import UserComponent from "./users/index.vue";

const editlight = require("@/assets/images/edit-grey.svg");
const bank = require("@/assets/images/bank.svg");
const product = require("@/assets/images/product.svg");
const edit = require("@/assets/images/edit.svg");

export default {
  name: "index",
  components: {
    AuditLog,
    BankAccounts,
    BusinessOwnership,
    BusinessProfile,
    ContactDetails,
    GeneralInformation,
    OnboardingAgreement,
    ProductSelection,
    S3FileGenerator,
    SupportingDocument,
    UserComponent,
  },
  data() {
    return {
      isReady: false,
      dataReady: false,
      dataError: false,
      onboardingId: "",
      onboardDetail: {},
      editlight: editlight,
      bank: bank,
      product: product,
      edit: edit,
      businessOwnershipCustomFields: [],
      legalId: "",
      currentLegal: {},
      legalsReady: false,
      appUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  mounted() {
    this.onboardingId = this.$route.params.onboardingId;

    if (this.$refs.businessInfoCollapse) {
      this.$refs.businessInfoCollapse.maxHeight = "none !important";
    }
  },

  methods: {
    ...mapActions("onboardingAdmin", ["fetchOnboardReviewDetailWithId"]),

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    async getDetail() {
      try {
        this.$vs.loading();
        await this.fetchOnboardReviewDetailWithId(this.$route.params.onboardingId).then((result) => {
          if (result) {
            this.onboardDetail = result.data.data;
            this.userId = result.data.data.userId;
            this.businessOwnershipCustomFields = result.data.data.onboardingRequest.businessOwnershipFields;

            if (result.data.data.terms.hasOwnProperty("generalTermsId")) {
              this.legalId = result.data.data.terms.generalTermsId;
              this.getTerms();
            }

            this.isReady = true;
            this.dataReady = true;
          } else {
            this.dataReady = true;
            this.dataError = true;
          }

          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.dataReady = true;
          this.dataError = true;
          this.$vs.notify({
            title: "Error",
            text: "Error fetching details",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
      } catch (ex) {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching details",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },

    getTerms() {
      this.$store.dispatch("legals/fetchLegalWithId", { id: this.legalId, type: "DASHBOARD" }).then((result) => {
        this.$vs.loading.close();

        const legals = result.data.data.legals;
        const latestPrivacy = result.data.data.privacy;
        const latestWebsiteTerms = result.data.data.websiteTerms;
        this.currentLegal = legals.find((el) => el._id === this.legalId);

        if (this.currentLegal.explicitTerms) {
          this.currentLegal.explicitTerms = this.replaceLegalContent(
            this.currentLegal.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}terms-view/${this.currentLegal._id}?type=DASHBOARD" target="_blank">${this.currentLegal.documentName}</a>`,
              privacy: `<a href="${this.appUrl}terms-view/${latestPrivacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestPrivacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}terms-view/${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestWebsiteTerms.documentName}</a>`,
            }
          );
          this.legalsReady = true;
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Error fetching legals",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },

  beforeMount() {
    this.getDetail();
  }
};
</script>

